<template>
  <div class="container topCon">
    <el-page-header @back="goBack" content="资料填写">
    </el-page-header>
<!--    <div class="ztTop"><span class="ztTitle">资料填写</span></div>-->
    <div class="wrapDiv">
      <div class="applyInfo">
        <div class="info">
          <p>申请单信息</p>
        </div>
        <div class="ztInfoWrap">
          <div class="ztInfo"> 已选主体类型 <span style="margin-left: 23px;">{{applyData.subjectType|formatType}}</span></div>
          <div  class="ztInfo"> 创建时间 <span style="margin-left: 23px;">{{applyData.createTime}}</span></div>
          <div > 申请单号 <span style="margin-left: 23px;">{{applyData.businessCode}}</span></div>
        </div>
      </div>

      <div class="requiredInfo">
        <div  class="info">
          <p>必填信息</p>
        </div>
        <div>
          <div class="rowInfo"  @click="toWriteInfo(1)" >
            <div class="rowInfoLeft">
              <i class="el-icon-circle-concentric "></i>
            </div>
            <div>
              <p class="title">主体信息</p>
              <p style="color: #999;margin-top: 3px;">请填写商家的营业执照/登记证书、经营者/法人的证件等信息</p>
            </div>
            <div class="rowInfoStatus" v-if="num == 1">
              <span class="gray">去填写＞</span>
            </div>
          </div>
          <a class="rowInfo" @click="toWriteInfo(2)">
            <div class="rowInfoLeft">
              <i :class=" subjectInfoWrite "></i>
            </div>
            <div>
              <p class="title">经营信息</p>
              <p style="color: #999;margin-top: 3px;">请填写商家的经营业务信息、售卖商品/提供服务场景信息</p>
            </div>
            <div class="rowInfoStatus" v-if="num == 2">
              <span class="gray">去填写＞</span>
            </div>
          </a>
          <a class="rowInfo" @click="toWriteInfo(3)" >
            <div class="rowInfoLeft">
              <i :class=" businessInfoWrite "></i>
            </div>
            <div>
              <p class="title">行业资质</p>
              <p style="color: #999;margin-top: 3px;">请填写商家所属行业、特殊资质等信息</p>
            </div>
            <div class="rowInfoStatus" v-if="num == 3">
              <span class="gray">去填写＞</span>
            </div>
          </a>
          <a class="rowInfo" @click="toWriteInfo(4)">
            <div class="rowInfoLeft">
              <i :class=" settlementInfoWrite "></i>
            </div>
            <div>
              <p class="title">结算账户</p>
              <p style="color: #999;margin-top: 3px;">请填写商家提现收款的银行账户信息</p>
            </div>
            <div class="rowInfoStatus" v-if="num == 4">
              <span class="gray">去填写＞</span>
            </div>
          </a>
          <a class="rowInfo lastRow" @click="toWriteInfo(5)">
            <div class="rowInfoLeft">
              <i :class=" bankAccountInfoWrite "></i>
            </div>
            <div>
              <p class="title">超级管理员</p>
              <p style="color: #999;margin-top: 3px;">请填写商家的超级管理员信息， 超级管理员需在开户后进行签约，并接收日常重要管理信息和进行资金操作，请确定其为商户法定代表人或负责人</p>
            </div>
            <div class="rowInfoStatus" v-if="num == 5">
              <span class="gray">去填写＞</span>
            </div>
          </a>
        </div>
      </div>

<!--      <div class="optionalInfo">-->
<!--        <div  class="info">-->
<!--          <p>选填信息</p>-->
<!--        </div>-->
<!--        <div>-->
<!--          <a class="rowInfo lastRow" @click="toWriteInfo(6)">-->
<!--            <div>-->
<!--              <p class="title">补充材料</p>-->
<!--              <p style="color: #999;margin-top: 3px;">根据实际审核情况，额外要求商家提供指定的补充资料</p>-->
<!--            </div>-->
<!--            <div class="rowInfoStatus">-->
<!--              <span class="gray">去填写＞</span>-->
<!--            </div>-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
      <div class="bottom">
        <el-button class="submitBtn" @click="submit"   v-loading.fullscreen.lock="fullscreenLoading" v-if="this.isSee == 'false'"><span>提交</span></el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {getWxMerchant,submitToWx} from "../../../api/wxMerchant";

export default {
  name: "merchantApplyInfo",
  components: {},
  data() {
    let info = JSON.parse(localStorage.getItem('info'));
    return {
      applyData:{},
      subjectInfoWrite:'el-icon-circle-dot',
      businessInfoWrite:'el-icon-circle-dot',
      settlementInfoWrite:'el-icon-circle-dot',
      bankAccountInfoWrite:'el-icon-circle-dot',
      num:1,
      fullscreenLoading:false,
      loading:null
    };

  },
  computed: {},
  filters:{
    formatType(type){
      if ('SUBJECT_TYPE_INDIVIDUAL'==type){
        return '个体户'
      }else if ('SUBJECT_TYPE_ENTERPRISE' == type){
        return '企业'
      }else if ('SUBJECT_TYPE_GOVERNMENT'==type){
        return '政府机关'
      }else if ('SUBJECT_TYPE_INSTITUTIONS'==type){
        return '事业单位'
      }else if ('SUBJECT_TYPE_OTHERS'==type){
        return '社会组织'
      }
    }
  },
  watch: {},
  mounted() {
    this.merchantId = this.$route.query.merchantId
    this.isSee = this.$route.query.isSee
  },
  created() {
    if (this.$router.history.current.query.isSourceAdmin) {
      localStorage.setItem('isSourceAdmin', this.$router.history.current.query.isSourceAdmin);
    }
    this.applyData = {merchantId:this.$route.query.merchantId,businessCode:this.$route.query.businessCode,subjectType:this.$route.query.subjectType,createTime:this.$route.query.createTime}
    console.log(this.applyData)
    this.merchantId = this.$route.query.merchantId
    this.isSee = this.$route.query.isSee
    console.log(this.isSee)
    this.getMerchantInfo()
  },
  methods: {
    goBack() {
      this.$router.push({ path: "/finance/merchantApply" });
    },
    async getMerchantInfo(){
      getWxMerchant({merchantId:this.merchantId}).then(response=>{
        if (response.code == '200'){
          this.applyData = response.data
          if (this.applyData.subjectInfoFinish){
            this.subjectInfoWrite = 'el-icon-circle-concentric'
            this.num = this.num + 1;
          }
          if (this.applyData.businessInfoFinish){
            this.businessInfoWrite = 'el-icon-circle-concentric'
            this.num = this.num + 1;
          }
          if (this.applyData.settlementInfoFinish){
            this.settlementInfoWrite = 'el-icon-circle-concentric'
            this.num = this.num + 1;
          }
          if (this.applyData.bankAccountInfoFinish){
            this.bankAccountInfoWrite = 'el-icon-circle-concentric'
            this.num = this.num + 1;
          }
          if (this.applyData.contactInfoFinish){
            this.num = this.num + 1;
          }
        }
      })
    },
    toWriteInfo(num){
      //1主体信息 2经营信息 3行业资质 4结算账户 5超级管理员信息 6 补充材料
      if (num == 1){
        this.$router.push({path:'/finance/merchantSubjectInfo',query:{'merchantId':this.applyData.merchantId,isSee:this.isSee}})
      }else if (num == 2){
        this.$router.push({path:'/finance/managementInfo',query:{'merchantId':this.applyData.merchantId,isWrite:this.applyData.subjectInfoFinish,isSee:this.isSee}})
      }else if (num == 3){
        this.$router.push({path:'/finance/settlementInfo',query:{'merchantId':this.applyData.merchantId,isWrite:this.applyData.businessInfoFinish,isSee:this.isSee}})
      }else if (num == 4){
        this.$router.push({path:'/finance/bankAccountInfo',query:{'merchantId':this.applyData.merchantId,merchantType:this.applyData.subjectType,isWrite:this.applyData.settlementInfoFinish,isSee:this.isSee}})
      }else if (num == 5){
        this.$router.push({path:'/finance/contactInfo',query:{'merchantId':this.applyData.merchantId,isWrite:this.applyData.bankAccountInfoFinish,isSee:this.isSee}})
      }else if (num == 6 ){
        this.$router.push({path:'/finance/additionInfo',query:{'merchantId':this.applyData.merchantId,isSee:this.isSee}})
      }
    },
    openFullScreen2() {
      this.loading = this.$loading({
        lock: true,
        text: '提交中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    submit(){
      this.openFullScreen2()
      let obj = {
        merchantId:this.applyData.merchantId
      }
      submitToWx(obj).then(response=>{
        this.loading.close();
      }).catch(e=>{
        this.loading.close();
      })
    }
  }
};
</script>

<style scoped>
.ztTitle{
  font-size: 18px;
}
.ztzy{
  display: inline-block;
  margin-left: 20px;
  font-size: 14px;
  color: #1b1bae;
}
.topCon {
  position: relative;

}
.info{
  width: 100%;
  margin-bottom: 30px;
  line-height: 1;
  padding-left: 10px;
  border-left: 3px solid #00c250;
  padding-right: 13px;
  font-size: 14px;
  font-weight: 400;
}
.wrapDiv{
  margin-top: 20px;
  font-size: 15px;
  padding: 32px 24px;
  margin-bottom: 24px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 4px -1px rgb(0 0 0 / 8%);
}
.ztInfo{
  float: left;
  margin-left: 14px;
  margin-right: 220px;
}
.ztInfoWrap{
  width: 100%;

}
.requiredInfo{
  margin-top: 38px;
}
.rowInfo{
  padding: 20px 0;
  border-top: 1px solid #e0e0e0;
  margin-left: 45px;
  position: relative;
  display: block;
  cursor: pointer;
}
.lastRow{
  border-bottom: 1px solid #e0e0e0;
}
.rowInfo:before {
  content: "";
  width: 0;
  height: 54px;
  position: absolute;
  left: -30px;
  top: 48px;
  border-left: 1px solid #e0e0e0;
}
.lastRow:before{
  border-left: 0px solid #FFFFFF;

}
a {
  color: #4466AD;
  text-decoration: none;
}
.rowInfoStatus{
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -11px;
}
.gray{
  color: #999!important;
}
.optionalInfo{
  margin-top: 55px;
}
.submitBtn{
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: .1s;
  font-weight: 500;
  padding: 12px 40px;
  font-size: 14px;
  border-radius: 4px;
  color: #fff;
  background-color: #00c250;
  border-color: #00c250;
}
.bottom{
  margin-top: 50px;
  margin-bottom: 50px;
}
.submitBtn:hover{
  background: #33ce73;
  border-color: #33ce73;
  color: #fff;
}
.rowInfoLeft{
  position: absolute;
  left: -40px;
  top: 19px;
  font-size: 20px;
}
.el-icon-circle-concentric{
  width: 22px;
  height: 22px;
  position: relative;
  border-radius: 100%;
  background-color: #ccf3dc;
}
.el-icon-circle-concentric:after{
  border-radius: 100%;
  content: "";
  left: 50%;
  top: 50%;
  width: 8px;
  height: 8px;
  background-color: #00c250;
  position: absolute;
  transform: translate(-50%,-50%);
}
.el-icon-circle-dot:after {
  width: 8px;
  height: 8px;
  background-color: #e5e5e5;
  position: absolute;
  transform: translate(-50%,-50%);
  border-radius: 100%;
  content: "";
  left: 50%;
  top: 50%;
}
.el-icon-circle-dot{
  width: 22px;
  height: 22px;
  position: relative;
  border-radius: 100%;
  background-color: #fff;
}
.title{
  font-weight: 700;
  color: #333;
}
</style>
